.pricerule-container {
  position: relative;
  margin: 8px;
  padding: 8px;
  box-sizing: border-box;
  background-color: #f1efe0;
  border-radius: 4px;
  border: 1px solid #dfe2e6;
}

.pricerule-container[data-changed="true"] {
  background-color: #ffaa79;
}

.pricerule-container h5 {
  color: #5a5a5a;
}

.pricerule-attributes {
  margin: 16px;
  box-sizing: border-box;
}

.pricerule-actionicon {
  cursor: pointer;
  color: rgba(0, 0, 0, .6);
}

.pricerule-confirmremove {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.pricerule-confirmremovepopup {
  width: 300px;
  max-width: 90%;
  background-color: white;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  border-radius: 4px;
}

