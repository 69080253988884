.header-navbar {
    border: 1px solid #dfe2e6 !important;
    padding: 0 16px !important;
}

.header-nav {
  font-size: 14px !important;
  height: 49px;
}

.header-mainlink {
  font-weight: bold !important;
  color: #1a5778 !important;
  height: 100% !important;
}

.header-nav .active {
  color: #1f2933 !important;
  font-weight: bold !important;
  border-bottom: 2px solid #245778 !important;
}

.header-navlink {
  display: flex;
  align-items: center;
  justify-content: center;
}
