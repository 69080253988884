.addressinput-group {
  position: relative;
}

.form-control.is-invalid, .was-validated .addressinput-field:invalid,
.form-control.is-invalid:focus, .was-validated .addressinput-field:invalid:focus {
  background-image: none;
  border-color: #ced4da;
  outline: none;
  box-shadow: none;
}

.form-control.is-invalid:focus, .was-validated .addressinput-field:invalid:focus {
  border-color: #99a5b3;
}

.addressinput-predictions {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.05);
  border: 1px solid #ced4da;
  display: flex;
  flex-direction: column;
  padding: 2px;
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  width: 100%;
}