.requestDetails {
  border: 1px solid #dfe2e6;
  border-radius: 8px;
  background-color: #fff;
  color: #636a71;
  padding: 21px;
  margin: 20px 47px;
  background: white;
}

.arrowLogo {
  width: 30px;
  margin-right: 10px;
}

.arrowLeft {
  line-height: 2.5;
  cursor: pointer;
  margin: 20px 47px 0px 47px;
}

.requestDetails button {
  margin-right: 0px;
  float: right;
}

.requestDetails .badge {
  margin-right: 0px;
  float: right;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}

.requestDetails button:last-child {
  margin-right: 10px;
}

.requestDetails .customInputGroup {
  border: 0em;
  background-color: white;
}

.requestDetails h6,
h4 {
  color: #105285;
  font-weight: bold;
}

.requestDetails h4 {
  margin-bottom: 30px;
}

.customInputGroup {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.disabledInputGroup {
  color: #495057;
  background-color: #e9ecef !important;
}

.requestStatus {
  margin: auto;
}

.requestStatus h4 {
  margin-bottom: 0px;
}

.request-pricing {
  margin-top: 20px;
}

.Objects-input {
  margin-bottom: 20px;
}

.form-control:disabled {
  background-color: #e9ecef !important;
}
