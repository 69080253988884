.object-controls {
  display: flex;
  align-items: center;
}

.object-counter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.object-title {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
}

.numberCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: #f5f5f5;
  font-size: 12px;
  color: #666;
  text-align: center;
}

.object-controls-plus {
  font-size: 12px;
  color: blue !important;
  cursor: pointer;
}

.object-controls-minus {
  font-size: 12px;
  cursor: pointer;
}

.delete-object-btn {
  color: red;
  cursor: pointer;
}

.mountingContainer {
  display: flex;
  align-items: center;
  font-size: 12px;
  border: solid;
  padding: 2px;
  cursor: pointer;
  border-radius: 0.25rem;
}

.mountingContainer-selected {
  display: flex;
  align-items: center;
  font-size: 12px;
  border: solid blue;
  background-color: blue;
  color: #fff;
  padding: 2px;
  cursor: pointer;
  border-radius: 0.25rem;
}

.inputArrowHide::-webkit-outer-spin-button,
.inputArrowHide::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-number{
  -moz-appearance: textfield;
  width: 40px !important;
}
