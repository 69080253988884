.slideout {
  position: fixed;
  top: 100px;
  right: -400px;
  width: 450px;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, .38);
  border-radius: 4px;
  display: flex;
  align-items: stretch;
  gap: 16px;
}

.slideout[data-visible="true"] {
  right: -10px;
}

.slideout-tongue {
  color: rgba(0, 0, 0, .54);
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px;
}

.slideout-tongue:hover {
  color: rgba(0, 0, 0, .84);
  background-color: rgba(0, 0, 0, .12);
}

.slideout-content {
  padding: 16px;
}

