.objects-picker-container {
  position: relative;
}

.objectinput-predictions {
  max-height: 300px;
  overflow-y: scroll;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #ced4da;
  display: flex;
  flex-direction: column;
  padding: 2px;
  box-sizing: border-box;
  z-index: 2;
  width: 100%;
  position: absolute;
}

.objectlistitem-item {
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  margin: 2px;
  padding: 2px;
  box-sizing: border-box;
  border-radius: 2px;
}

.objectlistitem-item:hover {
  background-color: #f0f3f7;
}

.objectlistitem-itemname {
  grid-area: name;
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.objectlistitem-itemname::after {
  content: attr(data-content);
}

.objectlistitem-itemname[data-content=" "]::after {
  content: attr(data-alt);
}
