.deleteIcon,.editIcon{
    cursor: pointer;
}

.emptyRow {
    text-align: center;
}

.requests-empty {
    margin-top: 100px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }