.dayItem {
  cursor: pointer;
  font-size: small;
}
.codePromoItem {
  text-align: left;
}

.promotionalCodes-tab {
  padding-top: 15px;
}
