.usermenu-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.usermenu-avatar {
  background-color: #e7f4fd;
  border: 2px solid #e2e2e2;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: border .1s ease-in;
}
.usermenu-avatar:hover {
  border-color: #99a5b3;
}
.usermenu-icon {
  color: #245778;
  margin-bottom: -10px;
}
