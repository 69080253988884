.helplink-a, .helplink-a:visited, .helplink-a:hover {
  margin: 0 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
  color: inherit;
}

.helplink-icon {
  color: #99a5b3;
}

.helplink-label {
  margin: 0 8px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 0px;
  opacity: 0;
  transition: all .5s ease-in;
}

.helplink-a:hover .helplink-label {
  max-width: 300px;
  opacity: 1;
}
