@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
.usermenuitem-item {
  paddingLeft: 4px;
  paddingRight: 4px;
}
.usermenuitem-icon {
  margin: 0 4px;
  color: #99a5b3;
}
.usermenuitem-label {
  margin: 0 4px
}

.usermenu-toggle {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  cursor: pointer;
}
.usermenu-avatar {
  background-color: #e7f4fd;
  border: 2px solid #e2e2e2;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: border .1s ease-in;
}
.usermenu-avatar:hover {
  border-color: #99a5b3;
}
.usermenu-icon {
  color: #245778;
  margin-bottom: -10px;
}

.helplink-a, .helplink-a:visited, .helplink-a:hover {
  margin: 0 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
  color: inherit;
}

.helplink-icon {
  color: #99a5b3;
}

.helplink-label {
  margin: 0 8px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 0px;
  opacity: 0;
  transition: all .5s ease-in;
}

.helplink-a:hover .helplink-label {
  max-width: 300px;
  opacity: 1;
}

.header-navbar {
    border: 1px solid #dfe2e6 !important;
    padding: 0 16px !important;
}

.header-nav {
  font-size: 14px !important;
  height: 49px;
}

.header-mainlink {
  font-weight: bold !important;
  color: #1a5778 !important;
  height: 100% !important;
}

.header-nav .active {
  color: #1f2933 !important;
  font-weight: bold !important;
  border-bottom: 2px solid #245778 !important;
}

.header-navlink {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slideout {
  position: fixed;
  top: 100px;
  right: -400px;
  width: 450px;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, .38);
  border-radius: 4px;
  display: flex;
  align-items: stretch;
  grid-gap: 16px;
  gap: 16px;
}

.slideout[data-visible="true"] {
  right: -10px;
}

.slideout-tongue {
  color: rgba(0, 0, 0, .54);
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px;
}

.slideout-tongue:hover {
  color: rgba(0, 0, 0, .84);
  background-color: rgba(0, 0, 0, .12);
}

.slideout-content {
  padding: 16px;
}


.pricepreview-attributerow {
  margin: 4px 0;
}

.pricerule-container {
  position: relative;
  margin: 8px;
  padding: 8px;
  box-sizing: border-box;
  background-color: #f1efe0;
  border-radius: 4px;
  border: 1px solid #dfe2e6;
}

.pricerule-container[data-changed="true"] {
  background-color: #ffaa79;
}

.pricerule-container h5 {
  color: #5a5a5a;
}

.pricerule-attributes {
  margin: 16px;
  box-sizing: border-box;
}

.pricerule-actionicon {
  cursor: pointer;
  color: rgba(0, 0, 0, .6);
}

.pricerule-confirmremove {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.pricerule-confirmremovepopup {
  width: 300px;
  max-width: 90%;
  background-color: white;
  padding: 8px;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  align-items: center;
  border-radius: 4px;
}


.pagespinner-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customtable-container {
  border-radius: 8px;
  border: 1px solid #dfe2e6;
  overflow: hidden;
  font-size: 14px !important;
}

.customtable-table {
  margin-bottom: 0 !important;
}

.customtable-table thead th {
  background-color: #f5f6f7;
  color: #636a71 !important;
  font-weight: normal;
  border-top-width: 0;
  border-bottom-width: 0;
}

.customtable-table tbody td {
  background-color: #fff;
}
.pricebuilder-actionicon {
  cursor: pointer;
  color: rgba(0, 0, 0, .6);
}

.pricebuilder-backlink,
.pricebuilder-backlink:visited,
.pricebuilder-backlink:active,
.pricebuilder-backlink:hover {
  color: rgba(0, 0, 0, 0.54);
}

.clientform-subsection {
  margin: 0 16px;
}

.clientform-hint {
  color: rgba(0, 0, 0, .54);
}

.clientform-actionicon {
  color: rgba(0, 0, 0, .54);
  cursor: pointer;
}

.clientform-vehicle {
  padding: 4px;
}

.clientform-vehicle:hover {
  cursor: pointer;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .06);
}

.storeAddress {
  padding: 7px 0;
}

.editAddress{
  margin: auto;
}
.addresslistitem-item {
  display: grid;
  width: 100%;
  height: 52px;
  font-size: 14px;
  grid-template-areas: "icon name"
                       "icon address";
  grid-template-rows: auto auto;
  grid-template-columns: 52px auto;
  cursor: pointer;
  margin: 2px;
  padding: 2px;
  box-sizing: border-box;
  border-radius: 2px;
}

.addresslistitem-item:hover {
  background-color: #f0f3f7;
}

.addresslistitem-itemicon {
  grid-area: icon;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addresslistitem-itemname {
  grid-area: name;
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.addresslistitem-itemname::after{
  content: attr(data-content);
}
.addresslistitem-itemname[data-content=" "]::after{
  content: attr(data-alt);
}

.addresslistitem-itemaddress {
  grid-area: address;
}
.addressinput-group {
  position: relative;
}

.form-control.is-invalid, .was-validated .addressinput-field:invalid,
.form-control.is-invalid:focus, .was-validated .addressinput-field:invalid:focus {
  background-image: none;
  border-color: #ced4da;
  outline: none;
  box-shadow: none;
}

.form-control.is-invalid:focus, .was-validated .addressinput-field:invalid:focus {
  border-color: #99a5b3;
}

.addressinput-predictions {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.05);
  border: 1px solid #ced4da;
  display: flex;
  flex-direction: column;
  padding: 2px;
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  width: 100%;
}
.address-iconcontainer {
  display: inline-block;
}

.address-iconcontainer[status="empty"] img {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.address-iconcontainer[status="valid"] img {
  -webkit-filter: none;
          filter: none;
}

.address-iconcontainer[status="invalid"] {
  background-color: red;
}

.address-iconcontainer[status="invalid"] img {
  mix-blend-mode: darken;
}

.address-illustration-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.address-phone-flag {
  width: 20px;
}

.address-invalidmsg {
  color: red;
}

.clientform-subsection {
  margin: 0 16px;
}

.clientform-hint {
  color: rgba(0, 0, 0, .54);
}

.clientform-actionicon {
  color: rgba(0, 0, 0, .54);
  cursor: pointer;
}

.clientform-vehicle {
  padding: 4px;
}

.clientform-vehicle:hover {
  cursor: pointer;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .06);
}

.storeAddress {
  padding: 7px 0;
}

.editAddress{
  margin: auto;
}

.date{
  width: 20;
}
.clientusers-actionicon {
  cursor: pointer;
  color: rgba(0, 0, 0, .6);
}

.clientinfos-subsection {
  margin: 0 16px;
}

.pagesubtitle-container {
  background-color: #fff;
  padding: 16px 0;
}
.deleteIcon,.editIcon{
    cursor: pointer;
}

.emptyRow {
    text-align: center;
}

.requests-empty {
    margin-top: 100px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
.dayItem{
    cursor: pointer;
    font-size: small;
}
.dayItem {
  cursor: pointer;
  font-size: small;
}
.codePromoItem {
  text-align: left;
}

.promotionalCodes-tab {
  padding-top: 15px;
}


.requestDetails {
  border: 1px solid #dfe2e6;
  border-radius: 8px;
  background-color: #fff;
  color: #636a71;
  padding: 21px;
  margin: 20px 47px;
  background: white;
}

.arrowLogo {
  width: 30px;
  margin-right: 10px;
}

.arrowLeft {
  line-height: 2.5;
  cursor: pointer;
  margin: 20px 47px 0px 47px;
}

.requestDetails button {
  margin-right: 0px;
  float: right;
}

.requestDetails .badge {
  margin-right: 0px;
  float: right;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}

.requestDetails button:last-child {
  margin-right: 10px;
}

.requestDetails .customInputGroup {
  border: 0em;
  background-color: white;
}

.requestDetails h6,
h4 {
  color: #105285;
  font-weight: bold;
}

.requestDetails h4 {
  margin-bottom: 30px;
}

.customInputGroup {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.disabledInputGroup {
  color: #495057;
  background-color: #e9ecef !important;
}

.requestStatus {
  margin: auto;
}

.requestStatus h4 {
  margin-bottom: 0px;
}

.PhoneInput {
}

small:before {
  content: "• ";
  color: rgb(214, 188, 41);
}

.requestcanceller-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  width: 100%;
}

.requestcanceller-alert {
  color: red;
}

.requestcanceller-address {
  text-align: center;
}

.requestcanceller-body {
  background-color: #f5f6f7;
  color: #636a71;
  font-size: 14px;
  height: 300px;
  overflow-y: scroll;
}

.requestcanceller-label {
  cursor: pointer;
  height: 44px;
  text-transform: capitalize;
}

.requestcanceller-footer {
  display: flex;
  justify-content: flex-end;
  grid-gap: 8px;
  gap: 8px;
}

.requestcanceller-comment {
  border: 0;
  border-bottom: 1px solid #dfe2e6;
  color: #0a0b0c;
  transition: border-color .1s ease-in;
  width: 100%;
  outline: none;
  resize: none;
  background-color: transparent;
  overflow: hidden
}

.requestcanceller-comment:focus {
  border-color: #11a3eb;
}
.object-controls {
  display: flex;
  align-items: center;
}

.object-counter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.object-title {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
}

.numberCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: #f5f5f5;
  font-size: 12px;
  color: #666;
  text-align: center;
}

.object-controls-plus {
  font-size: 12px;
  color: blue !important;
  cursor: pointer;
}

.object-controls-minus {
  font-size: 12px;
  cursor: pointer;
}

.delete-object-btn {
  color: red;
  cursor: pointer;
}

.mountingContainer {
  display: flex;
  align-items: center;
  font-size: 12px;
  border: solid;
  padding: 2px;
  cursor: pointer;
  border-radius: 0.25rem;
}

.mountingContainer-selected {
  display: flex;
  align-items: center;
  font-size: 12px;
  border: solid blue;
  background-color: blue;
  color: #fff;
  padding: 2px;
  cursor: pointer;
  border-radius: 0.25rem;
}

.inputArrowHide::-webkit-outer-spin-button,
.inputArrowHide::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-number{
  -moz-appearance: textfield;
  width: 40px !important;
}

.objects-picker-container {
  position: relative;
}

.objectinput-predictions {
  max-height: 300px;
  overflow-y: scroll;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #ced4da;
  display: flex;
  flex-direction: column;
  padding: 2px;
  box-sizing: border-box;
  z-index: 2;
  width: 100%;
  position: absolute;
}

.objectlistitem-item {
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  margin: 2px;
  padding: 2px;
  box-sizing: border-box;
  border-radius: 2px;
}

.objectlistitem-item:hover {
  background-color: #f0f3f7;
}

.objectlistitem-itemname {
  grid-area: name;
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.objectlistitem-itemname::after {
  content: attr(data-content);
}

.objectlistitem-itemname[data-content=" "]::after {
  content: attr(data-alt);
}

.form-control[readonly] {
    background-color: #fff !important;
}
.EmailInput {
}

.requestDetails {
  border: 1px solid #dfe2e6;
  border-radius: 8px;
  background-color: #fff;
  color: #636a71;
  padding: 21px;
  margin: 20px 47px;
  background: white;
}

.arrowLogo {
  width: 30px;
  margin-right: 10px;
}

.arrowLeft {
  line-height: 2.5;
  cursor: pointer;
  margin: 20px 47px 0px 47px;
}

.requestDetails button {
  margin-right: 0px;
  float: right;
}

.requestDetails .badge {
  margin-right: 0px;
  float: right;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}

.requestDetails button:last-child {
  margin-right: 10px;
}

.requestDetails .customInputGroup {
  border: 0em;
  background-color: white;
}

.requestDetails h6,
h4 {
  color: #105285;
  font-weight: bold;
}

.requestDetails h4 {
  margin-bottom: 30px;
}

.customInputGroup {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.disabledInputGroup {
  color: #495057;
  background-color: #e9ecef !important;
}

.requestStatus {
  margin: auto;
}

.requestStatus h4 {
  margin-bottom: 0px;
}

.request-pricing {
  margin-top: 20px;
}

.Objects-input {
  margin-bottom: 20px;
}

.form-control:disabled {
  background-color: #e9ecef !important;
}

.login-container {
  height: 100vh;
}

.login-main-row {
  height: 100%;
}

.login-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
}

.login-logo {
  position: absolute;
  top: 12px;
  height: 30px;
}

.login-illustration-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #0d3b60;
}

.login-illustration {
  width: 80%;
}

.login-errormessage {
  color: #ff465c;
}

.pricebuilder-price {
  margin: 8px;
  padding: 8px;
  box-sizing: border-box;
  background-color: #f1efe0;
  border-radius: 4px;
  border: 1px solid #dfe2e6;
}

.pricebuilder-price h5 {
  color: #5a5a5a;
}

.pricebuilder-pricerule {
  margin: 16px;
  box-sizing: border-box;
}

.pricebuilder-actionicon {
  cursor: pointer;
  color: rgba(0, 0, 0, .6);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.page-content {
  margin-top: 49px;
}

.App-default-component {
    border: 1px solid #dfe2e6;
    border-radius: 8px;
    background-color: white;
    color: #636a71;
    padding: 32px;
}

[type="file"] {
  /* Style the color of the message that says 'No file chosen' */
  color: #ffffff;
  /* opacity:0  */
}
[type="file"]::-webkit-file-upload-button {

  display:none
}

[type="file"]::-webkit-file-upload-button:hover {
  background: #fff;
  border: 2px solid #535353;
  color: #17a2e1;
}
.select-stage-col{
  display: flex;
  padding: auto;
  width: 50px;
  
}
.stage-and-upload {
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  align-items:flex-start;
  width: 100%;
  /* background-color: green; */
}
.upload-csv {
  padding-left: 50%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  margin-top: 0%;
  margin-bottom: 0%;
}
.upload-file-block{
  display: flex;
  justify-content: flex-start;
  margin-top: 0%;
  background-color: yellow ;
}
.btn-upload {
  background: #007bff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  border: 2px solid;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  /* font-size: 12px; */
  outline: none;
  padding: 10px 25px;
  margin-top: 0;
  text-transform: uppercase;
  transition: all 1s ease; 
}
.download-btn {
  /* width: 35%;
  height: 50%; */
  padding: 10px 25px;
  margin: auto;
}
.select-stage {
  width: auto;
  margin-right: 0%;
}
.configDetails {
  display: flex;
  flex-direction: column;
}
.config-body { 
  padding: auto;
  width: 94%;
  margin: 2% 2% 2% 2%;
}
.distance-input {
  /* width: 30%; */
  /* padding: auto;
  margin-left: 0.2%;*/
  margin-bottom: 1%; 
}

.objects-input {
  /* width:100%;  */
  margin-bottom: 20px;
}
.add-dropoff-btn-1 {
  border-color: transparent;
  background-color: #fff;
  text-align: center;
  text-decoration: none;
  color: #105285;
}
.add-dropoff-btn-1:focus {
  visibility: hidden;
}
.add-dropoff-btn-2:focus {
  visibility: hidden;
}
.add-dropoff-btn-1:disabled {
  border-color: transparent;
  background-color: #fff;
  text-align: center;
  text-decoration: none;
  color: #105285;
}
.add-dropoff-btn-1:hover {
  border-color: transparent;
  background-color: #fff;
  text-align: center;
  text-decoration: none;
  color: #105285;
}
.add-dropoff-btn-2 {
  border-color: transparent;
  background-color: #fff;
  text-align: center;
  text-decoration: none;
  color: #105285;
}
.add-dropoff-btn-2:not([disabled]) {
  border-color: #fff;
  background-color: #fff;
  text-align: center;
  text-decoration: none;
  color: #105285;
}
.add-dropoff-btn-2:hover {
  border-color: transparent;
  background-color: #fff;
  text-align: center;
  text-decoration: none;
  color: #105285;
}

.dropoff-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.delete-dropoff-btn {
  padding: auto;
  color: red;
}
.flex-row {
  padding-left: 0.5%;
}
.add-dropoff-block {
  margin-top: 0%;
  margin-bottom: 1%;
}

.pricingInfo-pricing {
  width: 20%;
}

.configDetails {
  border: 1px solid #dfe2e6;
  border-radius: 8px;
  background-color: #fff;
  color: #636a71;
  padding: 21px;
  margin: 20px 47px 20px 10px;
  background: white;
}
.configDetails button {
  /* margin-right: 0px; */
  float: right;
}
.configDetails button:last-child {
  /* margin-right: 10px; */
}
.configDetails .customInputGroup {
  border: 0em;
  background-color: white;
}

.configDetails h6,
h4 {
  color: #105285;
  font-weight: bold;
}

.configDetails h4 {
  /* margin-bottom: 30px; */
}

.arrowLeft {
  line-height: 2.5;
  cursor: pointer;
  margin: 20px 47px 0px 47px;
}

.configDetails-col{
  /* margin: 3%; */
  width: 100%;
  max-width: 100%;
}
.submit-block{
  display: flex;
  justify-content:flex-end;
  
  /* margin-right: 10%; */
}
.row{
  width: 100%;
  /* background-color: red; */
}

.col{
  padding-right: 0;
}

body {
  margin: 0;
  background-color: #f0f3f7; 
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

