[type="file"] {
  /* Style the color of the message that says 'No file chosen' */
  color: #ffffff;
  /* opacity:0  */
}
[type="file"]::-webkit-file-upload-button {

  display:none
}

[type="file"]::-webkit-file-upload-button:hover {
  background: #fff;
  border: 2px solid #535353;
  color: #17a2e1;
}
.select-stage-col{
  display: flex;
  padding: auto;
  width: 50px;
  
}
.stage-and-upload {
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  align-items:flex-start;
  width: 100%;
  /* background-color: green; */
}
.upload-csv {
  padding-left: 50%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  margin-top: 0%;
  margin-bottom: 0%;
}
.upload-file-block{
  display: flex;
  justify-content: flex-start;
  margin-top: 0%;
  background-color: yellow ;
}
.btn-upload {
  background: #007bff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  border: 2px solid;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  /* font-size: 12px; */
  outline: none;
  padding: 10px 25px;
  margin-top: 0;
  text-transform: uppercase;
  transition: all 1s ease; 
}
.download-btn {
  /* width: 35%;
  height: 50%; */
  padding: 10px 25px;
  margin: auto;
}
.select-stage {
  width: auto;
  margin-right: 0%;
}
.configDetails {
  display: flex;
  flex-direction: column;
}
.config-body { 
  padding: auto;
  width: 94%;
  margin: 2% 2% 2% 2%;
}
.distance-input {
  /* width: 30%; */
  /* padding: auto;
  margin-left: 0.2%;*/
  margin-bottom: 1%; 
}

.objects-input {
  /* width:100%;  */
  margin-bottom: 20px;
}
.add-dropoff-btn-1 {
  border-color: transparent;
  background-color: #fff;
  text-align: center;
  text-decoration: none;
  color: #105285;
}
.add-dropoff-btn-1:focus {
  visibility: hidden;
}
.add-dropoff-btn-2:focus {
  visibility: hidden;
}
.add-dropoff-btn-1:disabled {
  border-color: transparent;
  background-color: #fff;
  text-align: center;
  text-decoration: none;
  color: #105285;
}
.add-dropoff-btn-1:hover {
  border-color: transparent;
  background-color: #fff;
  text-align: center;
  text-decoration: none;
  color: #105285;
}
.add-dropoff-btn-2 {
  border-color: transparent;
  background-color: #fff;
  text-align: center;
  text-decoration: none;
  color: #105285;
}
.add-dropoff-btn-2:not([disabled]) {
  border-color: #fff;
  background-color: #fff;
  text-align: center;
  text-decoration: none;
  color: #105285;
}
.add-dropoff-btn-2:hover {
  border-color: transparent;
  background-color: #fff;
  text-align: center;
  text-decoration: none;
  color: #105285;
}

.dropoff-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.delete-dropoff-btn {
  padding: auto;
  color: red;
}
.flex-row {
  padding-left: 0.5%;
}
.add-dropoff-block {
  margin-top: 0%;
  margin-bottom: 1%;
}

.pricingInfo-pricing {
  width: 20%;
}

.configDetails {
  border: 1px solid #dfe2e6;
  border-radius: 8px;
  background-color: #fff;
  color: #636a71;
  padding: 21px;
  margin: 20px 47px 20px 10px;
  background: white;
}
.configDetails button {
  /* margin-right: 0px; */
  float: right;
}
.configDetails button:last-child {
  /* margin-right: 10px; */
}
.configDetails .customInputGroup {
  border: 0em;
  background-color: white;
}

.configDetails h6,
h4 {
  color: #105285;
  font-weight: bold;
}

.configDetails h4 {
  /* margin-bottom: 30px; */
}

.arrowLeft {
  line-height: 2.5;
  cursor: pointer;
  margin: 20px 47px 0px 47px;
}

.configDetails-col{
  /* margin: 3%; */
  width: 100%;
  max-width: 100%;
}
.submit-block{
  display: flex;
  justify-content:flex-end;
  
  /* margin-right: 10%; */
}
.row{
  width: 100%;
  /* background-color: red; */
}

.col{
  padding-right: 0;
}
