.addresslistitem-item {
  display: grid;
  width: 100%;
  height: 52px;
  font-size: 14px;
  grid-template-areas: "icon name"
                       "icon address";
  grid-template-rows: auto auto;
  grid-template-columns: 52px auto;
  cursor: pointer;
  margin: 2px;
  padding: 2px;
  box-sizing: border-box;
  border-radius: 2px;
}

.addresslistitem-item:hover {
  background-color: #f0f3f7;
}

.addresslistitem-itemicon {
  grid-area: icon;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addresslistitem-itemname {
  grid-area: name;
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.addresslistitem-itemname::after{
  content: attr(data-content);
}
.addresslistitem-itemname[data-content=" "]::after{
  content: attr(data-alt);
}

.addresslistitem-itemaddress {
  grid-area: address;
}