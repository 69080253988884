.usermenuitem-item {
  paddingLeft: 4px;
  paddingRight: 4px;
}
.usermenuitem-icon {
  margin: 0 4px;
  color: #99a5b3;
}
.usermenuitem-label {
  margin: 0 4px
}
