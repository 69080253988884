.requestcanceller-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.requestcanceller-alert {
  color: red;
}

.requestcanceller-address {
  text-align: center;
}

.requestcanceller-body {
  background-color: #f5f6f7;
  color: #636a71;
  font-size: 14px;
  height: 300px;
  overflow-y: scroll;
}

.requestcanceller-label {
  cursor: pointer;
  height: 44px;
  text-transform: capitalize;
}

.requestcanceller-footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.requestcanceller-comment {
  border: 0;
  border-bottom: 1px solid #dfe2e6;
  color: #0a0b0c;
  transition: border-color .1s ease-in;
  width: 100%;
  outline: none;
  resize: none;
  background-color: transparent;
  overflow: hidden
}

.requestcanceller-comment:focus {
  border-color: #11a3eb;
}