.pricebuilder-actionicon {
  cursor: pointer;
  color: rgba(0, 0, 0, .6);
}

.pricebuilder-backlink,
.pricebuilder-backlink:visited,
.pricebuilder-backlink:active,
.pricebuilder-backlink:hover {
  color: rgba(0, 0, 0, 0.54);
}
