.clientform-subsection {
  margin: 0 16px;
}

.clientform-hint {
  color: rgba(0, 0, 0, .54);
}

.clientform-actionicon {
  color: rgba(0, 0, 0, .54);
  cursor: pointer;
}

.clientform-vehicle {
  padding: 4px;
}

.clientform-vehicle:hover {
  cursor: pointer;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .06);
}

.storeAddress {
  padding: 7px 0;
}

.editAddress{
  margin: auto;
}

.date{
  width: 20;
}