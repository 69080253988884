.address-iconcontainer {
  display: inline-block;
}

.address-iconcontainer[status="empty"] img {
  filter: grayscale(100%);
}

.address-iconcontainer[status="valid"] img {
  filter: none;
}

.address-iconcontainer[status="invalid"] {
  background-color: red;
}

.address-iconcontainer[status="invalid"] img {
  mix-blend-mode: darken;
}

.address-illustration-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.address-phone-flag {
  width: 20px;
}

.address-invalidmsg {
  color: red;
}
