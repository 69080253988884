.pricebuilder-price {
  margin: 8px;
  padding: 8px;
  box-sizing: border-box;
  background-color: #f1efe0;
  border-radius: 4px;
  border: 1px solid #dfe2e6;
}

.pricebuilder-price h5 {
  color: #5a5a5a;
}

.pricebuilder-pricerule {
  margin: 16px;
  box-sizing: border-box;
}

.pricebuilder-actionicon {
  cursor: pointer;
  color: rgba(0, 0, 0, .6);
}
